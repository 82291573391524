.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css?family=Nunito+Sans|Quicksand|Roboto');

body {
  background-color: #2f3a68;
  color: white;
  font-family: 'Nunito Sans', sans-serif;
}

@media screen {
  .logo {
    padding: 40px 0px 40px 40px;
  }
  .logo img {
    width: 200px;
    content: url(/img/logo.png);
  }
}

@media screen and (max-width: 480px) {
  .logo {
    padding: 20px 0px 20px 10px;
    text-align: center;
  }
  .logo img {
    width: 200px;
    content: url(/img/logo2.png);
  }
}



.welcomeTextHeadline {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 40px;
}

@media screen {
  .welcomeText {
  /*
    position: fixed;
    top: 50%;
    left: 0%;
    transform: translate(0, -50%);
  */
    margin-top: 0px;
    padding-left: 40px;
    font-size: 16px;
    font-family: 'Quicksand', sans-serif;
  }

  .footer {
    padding: 40px;
  }

  .logos {
    padding-left: 40px;
    padding-top: 40px;
  }
}

@media screen and (max-width: 480px) {
  .welcomeText {
  /*
    position: fixed;
    top: 50%;
    left: 0%;
    transform: translate(0, -50%);
  */
    margin-top: 0px;
    padding-left: 10px;
    font-size: 16px;
    font-family: 'Quicksand', sans-serif;
  }

  .footer {
    padding: 10px;
  }

  .logos {
    padding-left: 0px;
    padding-top: 40px;
    text-align: center;
  }
}

.welcomeImage {
  text-align: center;
}

.footer span {
  font-weight: bold;
  font-size: 16px;
}

.footer span {
  font-weight: bold;
}

.logos span {
  font-weight: bold;
  font-size: 16px;
}

a {
  color: white;
}

.personIcon {
  float: left;
  padding-right: 10px;
}

.footer {
  text-align: center;
}
